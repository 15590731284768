import React from 'react'
import {Grid} from '@mui/material'
import delevery from '../pics/delivery.webp'
import order from '../pics/order.webp'
import combo from '../pics/phoneIcon.webp'
import {
SpecialStyle,
SpecialIcon,
SpecialTitle,
SpecialDesc
} from '../components/styles/Specials.styled'



const IconFirstVariants ={
    before:{
        opacity:0,
        x:-200
    },
    after:{
        opacity:1,
        x:0,
        rotate:360,
        transition:{delay:.2,type:'spring',bounce:.25,stiffness:120,duration:5}
    },
    exit:{
        y:-100
    }
}

const IconSecondVariants ={
    before:{
        x:-200,
    },
    after:{
        x:0,
        transition:{delay:.3,type:'spring',bounce:.25,stiffness:120,duration:3}
    },
    exit:{
        y:-100
    }
}

const CallVariants ={
    before:{
    

    },
    after:{
        x:0,
        rotate:[19,0,19,0,19,19,0],

        transition:{type:'spring',repeat:Infinity}
    },
    exit:{
        y:-100
    }
}


const Specials = () => {

    return (
        <SpecialStyle>
            <Grid container direction='row' justifyContent='center' alignContent='center' textAlign='center'>

                    <Grid item xs={12} lg={4} >
                        
                        <Grid item xs={12}><SpecialIcon src={order} variants={IconFirstVariants} initial='before' whileInView='after'/></Grid>
                        <Grid item xs={12}><SpecialTitle  >Order Your Food</SpecialTitle></Grid>
                        <Grid item xs={12}><SpecialDesc />We will be up and running shortly </Grid>
                       
                    </Grid>
                    <Grid item xs={12} lg={4}>
                    <Grid item xs={12}><SpecialIcon  src={delevery} variants={IconSecondVariants} initial='before' whileInView='after'/></Grid>
                        <Grid item xs={12}><SpecialTitle>Delivery or Pickup</SpecialTitle></Grid>
                        <Grid item xs={12}><SpecialDesc/>We do pickup and delivery </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                    <Grid item xs={12}><SpecialIcon  src={combo} variants={CallVariants} initial='before' whileInView='after' style={{width:'100px'}}/></Grid>
                        <Grid item xs={12}><SpecialTitle>Call Us</SpecialTitle></Grid>
                        <Grid item xs={12}><SpecialDesc/>(416) We will update soon </Grid>
                    </Grid>
            </Grid>   
        </SpecialStyle>
    )
}

export default Specials
