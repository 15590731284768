import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
  CART_CLEAR_ITEMS,
  CART_INCREMENT_ITEM,
  CART_DECREMENT_ITEM,
  CART_CHANGE_SIZE,
  CART_RESET,
} from '../constants/cartConstants'

export const cartReducer = (
  state = { cartItems: [], shippingAddress: {}, customPizza: [] },
  action
) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      const item = action.payload
      
      const existItem = state.cartItems.find((x) => x.product === item.product)

      if (existItem) {
        if(existItem.name ==='Custom Pizza'){
          return {
            ...state,
            cartItems: [...state.cartItems, item],
          }
        }else
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.product === existItem.product ? item : x
          ),
        }
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item],
        }
      }
    case CART_CHANGE_SIZE:
        
        return { ...state, cartItems:Object.assign([],state.cartItems.map(item => {
          if(item.product === action.payload){
            //HANDLE WINGS SIZE 
            if(item.type ==='wings'){
              item.size = action.payloadTwo;
              item.totalPrice = item.quantity*item.price*item.size.slice(0,1)
            }
            //HANDLE FRIES SIZE
            if(item.type ==='fries'){
              switch(action.payloadTwo){
                case 'Regular':
                  item.size = action.payloadTwo;
                  
                  break;

                default:return state
              }
            }
            //HANDLE SALAD SIZE
            if(item.type==='salad'){
              switch(action.payloadTwo){
                case 'Regular':
                  item.size = action.payloadTwo;
                  item.item.totalPrice = item.quantity*4.99
                break;
                case 'Large':
                  item.size = action.payloadTwo;
                  item.item.totalPrice = item.quantity*7.99
                break;
                default:return state
              }
            }
            //HANDLE PIZZA SIZE
            if(item.type ==='pizza'){
              switch(action.payloadTwo){
                case 'Small':
                  item.size = action.payloadTwo;
                   item.totalPrice = item.quantity*7.99
                  break;
                case 'Medium':
                  item.size = action.payloadTwo;
                  item.totalPrice = item.quantity*9.99
                  break;
                case 'Large':
                  item.size = action.payloadTwo;
                    item.totalPrice = item.quantity*12.99
                   break;
                case 'X-Large':
                  item.size = action.payloadTwo;
                  item.totalPrice = item.quantity*14.99
                   break;
                case 'Party':
                  item.size = action.payloadTwo;
                  item.totalPrice = item.quantity*14.99
                  break;   
                default:return state
              }
            }
            //HANDLE COMBO SIZE
            if(item.type ==='combo'){
              switch(action.payloadTwo){
                case 'Small':
                  item.size = action.payloadTwo;
                   item.totalPrice = item.quantity*20.99
                  break;
                case 'Medium':
                  item.size = action.payloadTwo;
                  item.totalPrice = item.quantity*24.99
                  break;
                case 'Large':
                  item.size = action.payloadTwo;
                    item.totalPrice = item.quantity*29.99
                   break;
                case 'X-Large':
                  item.size = action.payloadTwo;
                  item.totalPrice = item.quantity*30.99
                   break;
                case 'Party':
                  item.size = action.payloadTwo;
                  item.totalPrice = item.quantity*41.99
                  break;   
                default:return state
              }
            }
          }
          return item
        }))};

    case CART_INCREMENT_ITEM:
          return { ...state, cartItems:Object.assign([],state.cartItems.map(item => {
            if(item.product === action.payload){
              item.quantity += 1;
            //HANDLE WINGS SIZE 
            if(item.type ==='wings'){
             
              item.totalPrice = item.quantity*item.price*Number(item.size.slice(0,1))
            }
            //HANDLE FRIES SIZE
            if(item.type ==='fries'){
              switch(action.payloadTwo){
                case 'Regular':
                  item.size = action.payloadTwo;
                  
                  break;

                default:return state
              }
            }
            //HANDLE SALAD SIZE
            if(item.type==='salad'){
              switch(item.size){
                case 'Regular':
                
                  item.totalPrice = item.quantity * 4.99
                break;
                case 'Large':
                
                  item.totalPrice = item.quantity * 7.99
                break;
                default:return state
              }
            }
            //HANDLE PIZZA SIZE
            if(item.type ==='pizza'){
              switch(item.size){
                case 'Small':
                  
                   item.totalPrice = item.quantity*7.99
                  break;
                case 'Medium':
                  
                  item.totalPrice = item.quantity*9.99
                  break;
                case 'Large':
                  
                    item.totalPrice = item.quantity*12.99
                   break;
                case 'X-Large':
                  
                  item.totalPrice = item.quantity*14.99
                   break;
                case 'Party':
                  
                  item.totalPrice = item.quantity*14.99
                  break;   
                default:return state
              }
            }
            //HANDLE COMBO SIZE
            if(item.type ==='combo'){
              switch(item.size){
                case 'Small':
                   item.totalPrice = item.quantity*20.99
                  break;
                case 'Medium':
                  item.totalPrice = item.quantity*24.99
                  break;
                case 'Large':
                    item.totalPrice = item.quantity*29.99
                   break;
                case 'X-Large':
                  item.totalPrice = item.quantity*30.99
                   break;
                case 'Party':
                  item.totalPrice = item.quantity*41.99
                  break;   
                default:return state
              }
            }

            if(item.type ==='drink'){
              item.totalPrice = item.quantity * item.price
            }
            if(item.type ==='sauce'){
              item.totalPrice = item.quantity * item.price
            }
            if(item.type ==='bread'){
              item.totalPrice = item.quantity * item.price
            }        
            }
            return item
          }))};
   
    case CART_DECREMENT_ITEM:
            return { ...state, cartItems:Object.assign([],state.cartItems.map(item => {
              if(item.product === action.payload){
                if(item && item.quantity > 1){
                  item.quantity  -= 1;
                    
             //HANDLE WINGS SIZE 
             if(item.type ==='wings'){
             
              item.totalPrice = item.quantity*item.price*Number(item.size.slice(0,1))
            }
            //HANDLE FRIES SIZE
            if(item.type ==='fries'){
              switch(action.payloadTwo){
                case 'Regular':
                  item.size = action.payloadTwo;
                  
                  break;

                default:return state
              }
            }
            //HANDLE SALAD SIZE
            if(item.type==='salad'){
              switch(item.size){
                case 'Regular':
                  item.totalPrice = item.quantity*4.99
                break;
                case 'Large':
                  item.totalPrice = item.quantity*7.99
                break;
                default:return state
              }
            }
            //HANDLE PIZZA SIZE
            if(item.type ==='pizza'){
              switch(item.size){
                case 'Small':
                  
                   item.totalPrice = item.quantity*7.99
                  break;
                case 'Medium':
                  
                  item.totalPrice = item.quantity*9.99
                  break;
                case 'Large':
                  
                    item.totalPrice = item.quantity*12.99
                   break;
                case 'X-Large':
                  
                  item.totalPrice = item.quantity*14.99
                   break;
                case 'Party':
                  
                  item.totalPrice = item.quantity*14.99
                  break;   
                default:return state
              }
            }
            //HANDLE COMBO SIZE
            if(item.type ==='combo'){
              switch(item.size){
                case 'Small':
                  
                   item.totalPrice = item.quantity*20.99
                  break;
                case 'Medium':
                  
                  item.totalPrice = item.quantity*24.99
                  break;
                case 'Large':
                  
                    item.totalPrice = item.quantity*29.99
                   break;
                case 'X-Large':
                  
                  item.totalPrice = item.quantity*30.99
                   break;
                case 'Party':
                  
                  item.totalPrice = item.quantity*41.99
                  break;   
                default:return state
              }
            }
            if(item.type ==='drink'){
              item.totalPrice = item.quantity * item.price
            }
            if(item.type ==='sauce'){
              item.totalPrice = item.quantity * item.price
            }
            if(item.type ==='bread'){
              item.totalPrice = item.quantity * item.price
            }    
                }else{
                  item.quantity=1
                }        
              }
              return item
            }))};
     
    case CART_REMOVE_ITEM:
        return {
          ...state,
          cartItems: state.cartItems.filter((x) => x.product !== action.payload),
          // customPizza: state.customPizza.filter((x)=>x.product !== action.payload)
      }
    case CART_SAVE_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: action.payload,
      }

    case CART_SAVE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      }
    case CART_CLEAR_ITEMS:
      return {
        ...state,
        cartItems: [],
      }
      case CART_RESET:
        return {
          
        }
    default:
      return state
  }
}
