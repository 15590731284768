import {
    STEPFOWARD,STEPBACKWARD,RESET
} from '../constants/stepperConstant'



export const StepperReducer = (state=0,action)=>{
    switch(action.type){
        case STEPFOWARD: if(state <2){return state +1}else{return state}
        case STEPBACKWARD: if(state>0){return state -1}else{return state}
        case RESET: return state = 0
        default:return state
    }
}