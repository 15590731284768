import styled from 'styled-components'
import {Mobile,MobiletoIpad,Ipad,Ipadto1080,desk1080,fourk} from '../../responsive'
import {motion} from 'framer-motion'

export const TopSpecialStyle = styled.div`
background-color:white; 
position: relative;
margin-top:100px;
padding:0;
width:100%;
min-height:100%;
height:100%;
overflow:hidden;
${Mobile({height:'20%',marginTop:'-.5px'})}
${MobiletoIpad({height:'110%',marginTop:'-.5px'})}
${Ipad({height:'110%',marginTop:'-.5px',marginBottom:'0vh'})}
${Ipadto1080({marginTop:'-.5px',marginBottom:'0vh',height:'100vh',minHeight:'100%'})}
${desk1080({height:'100%'})}
`

export const PaintDivider = styled.img`
position: absolute;
transform: scale(-1, -1);
top: -100.5px;
width: 100%;
z-index:'999';
${Mobile({top:'-1.6px',})}
${MobiletoIpad({top:'-0.9px'})}
${Ipad({top:'-2px'})}
${Ipadto1080({top:'-4vh'})}


`

export const PaintBottom = styled.img`
position: absolute;
bottom: -41px;
width: 110%;
transform: scale(-1,-1);
left:-25px;
z-index:'-999';
${Mobile({bottom:'-1vh'})}
${MobiletoIpad({bottom:'0vh'})}
${Ipadto1080({bottom:'0vh'})}
${desk1080({bottom:'0vh'})}
${fourk({left:"-34px",bottom:'0vh'})}

`

export const TopMainTitle = styled.h1`
font-family:Finger Paint;
margin-top:100px;
text-align:center;
font-size:40px;
${Mobile({fontSize:"12vw",marginTop:'5vh'})}
${Ipadto1080({fontSize:"8vw",marginTop:'10vh'})}
${desk1080({fontSize:"6vw",marginTop:'13vh'})}
${fourk({fontSize:"4vw",marginTop:'20vh'})}
`

export const TopOrderTitle = styled.h1`
font-family:Finger Paint;
${fourk({fontSize:"2vw"})}
`

export const TopOrderPic = styled.img`
position: relative;
width: 80%;
border-radius:50%;
margin: :20px;
transition: width 2s ease;

&:hover{
    width:90%;
}
${Mobile({transition:'none'})}
${Ipad({width:'70%'})}
${Ipadto1080({width:'55%'})}
`

export const Combodiv = styled.div`
height:100vh;
width:50vw;
position: relative;
text-align:center;
margin-top:10px;
display: flex;
flex-direction: column;
`

export const ComboImage = styled.img`
width:95vw;
border-radius:25px;
margin:20px;
max-width:700px;
margin-right:auto;
margin-left:auto;
${Mobile({marginTop:'0vh'})}
`

export const BoxDiv = styled.div`
width:100vw;
height:60vh;
    ${Mobile({height:'55vh',marginTop:'55px'})}
    ${MobiletoIpad({height:'85vh',marginTop:'75px'})}
    ${Ipad({height:'80vh',marginTop:'105px'})}
    ${Ipadto1080({height:'100vh',marginTop:'105px'})}
    ${desk1080({height:'100vh',marginTop:'25vh'})}
`

export const ComboTitle = styled(motion.h1)`
    text-align:center;
    margin-top:40vh;
    font-family:Finger Paint;
    font-size:4vw;
    ${Mobile({marginTop:'7vh',fontSize:"10vw"})}
    ${MobiletoIpad({})}
    ${Ipad({})}
    ${Ipadto1080({})}
    ${desk1080({})}
`

export const ComboDescription = styled(motion.p)`
    text-align:center;
    font-size:1.25vw;
    font-family:Finger Paint;
    margin:10px;
    ${Mobile({fontSize:"5vw",marginBottom:"-20px"})}
    ${MobiletoIpad({})}
    ${Ipad({})}
    ${Ipadto1080({})}
    ${desk1080({})}
`