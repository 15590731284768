import {Grid} from '@mui/material'
import firstPizza from '../pics/firstPizza.webp'
import singleBasil from '../pics/singleBasil.webp'
import bunchBasil from '../pics/bunchBasil.webp'
import multiBasil from '../pics/multiBasil.webp'


//MAIN STYLED COMPONENTS 
import {
  MainContainer,
  BackGroundImageContainer,
  Pizza,
  Basil1, 
  Basil2, 
  Basil3, 
  Title 
} from './styles/MainDisplay.styled'
  


//FRAMER MOTION FILES 
import {
  pizzaVariants,
  basil1Variants,
  basil2Variants,
  basil3Variants
} from './Framer-Motion/MainDisplayFramer'




  
const MainDisplay = () => {
    return (
        <MainContainer>
            <BackGroundImageContainer>
                <Grid container>
                    <Grid item xs={12}>
                        <Title className='mainTitle'>Pizza & Wings</Title>
                    </Grid>
                    <Grid item xs={12}>
                        <Pizza variants={pizzaVariants} initial='before' animate='after' src={firstPizza}/> 
                        <Basil1 variants={basil1Variants} initial='before' animate='after' src={singleBasil}/>
                        <Basil2 variants={basil2Variants} initial='before' animate='after' src={bunchBasil}/>
                        <Basil3 variants={basil3Variants} initial='before' animate='after' src={multiBasil}/>
                    </Grid>
                </Grid>
                
            </BackGroundImageContainer>
        </MainContainer>
    )
}

export default MainDisplay
