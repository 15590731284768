import React from 'react'

import styled from 'styled-components'
import ProductCard from './ProductCard'
import {motion} from 'framer-motion'


const ProductContainer = styled(motion.div)`
display:flex;
flex-wrap:wrap;
justify-content:center;

`


const Products = ({products}) => {

    if(products){
        return (
            <ProductContainer layout>
                {products.slice(0).reverse().map((p,i)=>(
 
                        
<ProductCard product={p} key={p._id} index={i} />
                    
                ))}
                
            </ProductContainer>
        )
    }else{
        return <h5>loading.......</h5>
    }
    
}

export default Products
