
export const pizzaVariants={
    
        before:{
            y:'200vh'
          },
        after:{
             y:'0vh',
            transition:{type:'spring',duration:3.5,ease:'easeInOut',bounce: 0.25}
        
          },
          exit:{
            x:'-100vw',
            transition:{ease:'easeInOut',duration:1.5}
          }
    }



export const basil1Variants ={
    before:{
        y:'-200vh'
      },
    after:{
         y:'0vh',
        transition:{type:'spring',duration:3, ease:'easeInOut',bounce: 0.30,delay:0.1}
    
      },
      exit:{
        x:'-100vw',
        transition:{ease:'easeInOut',duration:1.5}
      }
}

export const basil2Variants ={
    before:{
        y:'-200vh'
      },
    after:{
         y:'0vh',
        transition:{type:'spring',duration:5, ease:'easeInOut',bounce: 0.25, }
    
      },
      exit:{
        x:'-100vw',
        transition:{ease:'easeInOut',duration:1.5, delay:1}
      }
}

export const basil3Variants ={
    before:{
        y:'-200vh'
      },
    after:{
         y:'0vh',
        transition:{type:'spring',duration:5,ease:'easeInOut',bounce: 0.22, delay:.5}
    
      },
      exit:{
        x:'-100vw',
        transition:{ease:'easeInOut',duration:3.5}
      }
}

