import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import ProductMenu from './ProductMenu';
import styled from 'styled-components'
import {motion} from 'framer-motion'




const CardContainer = styled(motion.div)`
`


const ProductCard =({product,index})=> {
  const CardVariant ={
    hidden:{
      opacity:0,
      x:-200
    },
    visible:{
      opacity: 1,
      x:0,
      transition:{duration:1.5, type:'spring',bounce:.35,ease:'easeInOut', delay:index*0.2}
    },
    exit:{
      opacity: 0
    }
    }
    
  return (
    <CardContainer variants={CardVariant} initial='hidden' animate='visible' exit='exit' layout key={product._id}>
    <Card sx={{ maxWidth: 690 }} style={{textAlign:'center',backgroundColor:'transparent',color:'white',margin:'50px 10px 0px 10px',}}>
      <CardMedia
        component="img"
        height="240"
        image={product.image}
        alt="green iguana"
      />
      <CardContent  >
        <Typography gutterBottom variant="h5" component="div" >
          {product.name}
        </Typography>
        <Typography variant="body2" >
          {product.description}
        </Typography>
        <Typography variant="body2">
          {`$${(product.price).toFixed(2)}`}
        </Typography>
      </CardContent>
      <CardActions style={{display:'flex',justifyContent:'center',}}>
       <ProductMenu productId={product._id}/>
      </CardActions>
    </Card>
    </CardContainer>
  );
}

export default ProductCard