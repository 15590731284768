import styled from 'styled-components'
import {motion} from 'framer-motion'


export const SpecialStyle = styled.div`
position: relative;
background-color:#ff1616;  
margin:10;
padding:0;
width:100%;
min-height:300px;
height:100%;

`

export const SpecialIcon = styled(motion.img)`
font-size:25px;
width: 100px;
transition: filter 2s;
margin-top:20px;
&:hover {
    filter: invert(99%) sepia(0%) saturate(0%) hue-rotate(59deg) brightness(110%) contrast(100%);
  }
`
export const SpecialTitle = styled(motion.h1)`

`
export const SpecialDesc = styled(motion.p)`
color:white;
`
