import {Facebook,Instagram,Twitter,Room,Phone,MailOutline }from '@mui/icons-material';
import {Grid, Link} from '@mui/material'
import {
    FooterContainer,
    Left,
    Logo,
    Desc,
    SocialContainer,
    SocialIcon,
    Center,
    CenterTitle,
    List,
    ListItem,
    Right,
    RightTitle,
    ContactItem,
    Payment
} from "./styles/Footer.styled"



const Footer = () => {
    return (
        <FooterContainer>
            <Grid container direction='row' >
            <Grid item  xs={12} md={6} lg={4}>
            <Left>
                <Logo>Pizza Neapolitan</Logo>
                <Desc>Pizza & Wings</Desc>
                <SocialContainer>
                    <SocialIcon color='3B5999'>
                            <Facebook/>
                    </SocialIcon>
                    <SocialIcon color='E4405F'>
                            <Instagram />
                    </SocialIcon>
                    <SocialIcon color='E60023'>
                            <Twitter/>
                    </SocialIcon>
                </SocialContainer>
            </Left>

            </Grid>
            <Grid item  xs={12} md={6} lg={4}>
            <Center>
                <CenterTitle>Useful Links</CenterTitle>
                <List>
                    <ListItem><Link to='/'>Home</Link></ListItem>
                    <ListItem >
                    <a href="https://www.webfxnow.com/" style={{color:'white',textDecoration:'none'}}> Powered by: WEBFXNOW</a>
                        </ListItem>
                </List>
            </Center>

            </Grid>
            <Grid item xs={12} md={6} lg={4}>
            <Right>
                <RightTitle>Contact</RightTitle>
                <ContactItem><Room style={{marginRight:'10px'}}/>2900 Markham Rd, Scarborough Ontario</ContactItem>
                <ContactItem><Phone style={{marginRight:'10px'}}/>111 111 1111</ContactItem>
                <ContactItem><MailOutline style={{marginRight:'10px'}}/>admin@pizzaneapolitan.ca</ContactItem>
                <Payment src='https://i.ibb.co/Qfvn4z6/payment.png'/>
            </Right>
            </Grid>
            </Grid>
            
            
            
        </FooterContainer>
    )
}

export default Footer