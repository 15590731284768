import React from 'react'
import {Grid} from '@mui/material'
import FallingPizza from '../pics/fallingPizza.webp'
import blacktopdivider from '../pics/topBlack2.webp'


import {
AboutContainer,
AboutPaintDivider,
AboutDesc,
AboutImage,
AboutPaintBottom,
AboutTitle,
} from '../components/styles/AboutUs.styled'


export const AboutVariants ={
    before:{

    },
    after:{

    },
    exit:{

    }
}

export const TitleVariants ={
    before:{
        opacity: 0,
        x:-200
    },
    after:{
        opacity: 1,
        x:0,
        transition:{type:'spring',duration:2, bounce:.3,mass:1}
    },
    exit:{

    }
}

export const ParagraphVariants ={
    before:{
        opacity: 0,
        x:-200,
        rotate:180,
    },
    after:{
        opacity: 1,
        x:0,
        rotate:0,
        transition:{type:'spring',duration:2, bounce:.3,mass:1}
    },
    exit:{

    }
}

export const signatureVariants ={
    before:{
        opacity: 0
    },
    after:{
        opacity: 1,
        transition: {delay:1,duration:1.5,type:'tween'}
    },
    exit:{

    }
}

export const pizzaPicVariants ={
    before:{
        y:200,
        opacity:0
    },
    after:{
        opacity: 1,
        y:0,
        transition:{type:"spring", duration:2, delay:.5, bounce:.3, mass:2}
    },
    exit:{

    }
}


const About = () => {

    return (
        <AboutContainer id='aboutus'>
            <AboutPaintDivider style={{lineHeight:"0",marginTop:'-10px',zIndex:'999'}} src={blacktopdivider} alt='top'/>
            <Grid container  direction='row'>
                <Grid container direction='row' item xs={12} lg={6}  textAlign='center'>
                    <Grid item xs={12}>
                        <AboutTitle variants={TitleVariants} initial='before' whileInView={'after'}>ABOUT US</AboutTitle>
                        <AboutDesc variants={ParagraphVariants} initial='before' whileInView={'after'}>	We are delighted to welcome you to Pizza Neapolitan. We started Pizza Neapolitan 
                            with one purpose in mind, to provide the finest Pizza and Wings to all at an affordable price.
	                        Our promise at Pizza Neapolitan is to provide great service, use quality ingredients, and to make 
                            great pizzas and wings. If you need any assistance, please contact us. Thank you.  
                        </AboutDesc>
                        <AboutDesc style={{fontSize:'14px',fontWeight:'bolder'}} variants={signatureVariants} initial='before' whileInView={'after'}>
                            The team at Pizza Neapolitan
                        </AboutDesc>
                        </Grid>
                </Grid>
                <Grid container direction='row' item xs={12} lg={6} justifyContent='center' textAlign='center'>
                    <AboutImage src={FallingPizza} variants={pizzaPicVariants} initial='before' whileInView='after'/>   
                    
                </Grid>
            </Grid>
            <AboutPaintBottom style={{lineHeight:"0",marginTop:'-10px',zIndex:'999'}} src={blacktopdivider}/>
        </AboutContainer>
    )
}

export default About
