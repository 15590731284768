import React from 'react'
import {Field} from 'formik'
import styled from 'styled-components'
import {motion} from 'framer-motion'


//STYLED COMPONENTS

export const SelectComboComponent = styled(motion.select)`
width: 100%;
height:50px;
border-radius:10px;
text-align:center;
font-size:25px;
`

export const SelectContainer = styled(motion.div)`
margin-top:20px;
`
export const SelectLabel = styled(motion.h2)`
    font-size:20px;
    color:white;
`


  
  const AccordianVariants ={
    before:{
      x:"100vw"
    },
    after:{
      x:'0vw',
      transition:{type:'spring',duration:5, ease:'easeInOut',bounce: 0.25 }
    }
  }
  

  const SelectContainerVariants = {
    before: { opacity: 0, },
    after: {
       
      opacity: 1,
      transition: {
        staggerChildren: 0.9
      }
    },
    exit:{y:-200}
  }
  

  const TitleSelectVariants ={
    before:{
      x:"100vw"
    },
    after:{
      x:'0vw',
      transition:{type:'spring',duration:5, ease:'easeInOut',bounce: 0.25 }
    }
  }
  


  const item = {
    before: { opacity: 0,x:200, },
    after: { opacity: 1,x:0},

  }


const SelectComponent = (props) => {
    const {label,name,options,...rest} = props

  return (
    <SelectContainer  variants={SelectContainerVariants} initial='before' whileInView={'after'} exit={'exit'}>
         <motion.div variants={item} >
        <SelectLabel variants={TitleSelectVariants} initial='before' animate={{x:'0vw',transition:{type:'spring',duration:5, ease:'easeInOut',bounce: 0.25 }}} >{label}</SelectLabel>
       
        <Field as='select' id={name} name={name} {...rest} style={{height:'50px',width:'100%',borderRadius:"10px",textAlign:'center',fontSize:'25px'}} required='true' >
            {options.map(option=>{
                return(
                    <option key={option._id} value={option.name}>{option.name}</option>
                )
            })}
        </Field>
        </motion.div>
       
    </SelectContainer>
  )
}

export default SelectComponent