import styled from 'styled-components'
import {Mobile,MobiletoIpad,Ipad,Ipadto1080,twok} from '../../responsive'
import {motion} from 'framer-motion'







export const AboutContainer = styled(motion.div)`
    background-color:white;
    position: relative;
    margin:0px;
    padding:0px;
    width:100%;
    min-height:'100vh'
    height:100vh;
    font-family:Finger Paint;
    overflow:hidden;

`
export const AboutTitle = styled(motion.h1)`
margin-top:250px;
font-size:70px;
${Mobile({marginTop:'10vh',fontSize:'30px'})};
${MobiletoIpad({marginTop:'5vh'})}
${Ipad({marginTop:'10vh'})}
${Ipadto1080({marginTop:'16vh'})}
`
export const AboutDesc = styled(motion.p)`
margin:20px;
font-size:25px;
${Mobile({marginTop:'10vw',fontSize:'12px'})}
`
export const AboutImage = styled(motion.img)`
width: 30vw;
margin-top:180px;
${Mobile({marginTop:'5vw',width:'80vw'})}
${MobiletoIpad({marginTop:'1vh',width:'55vw'})}
${Ipad({width:'45vw'})}
${Ipadto1080({width:'25vw',margin:"10vh"})}
`
export const AboutPaintDivider = styled(motion.img)`
position: absolute;
top: -1px;
left:-15px;
width: 110vw;

${twok({left:'-1vw',top:'-50px'})}
`
export const AboutPaintBottom = styled(motion.img)`
position: absolute;
bottom: -41px;
left:-15px;
width: 110vw;
transform: scale(-1, -1);
${Mobile({fontSize:'30px',bottom:"-21px",zIndex:"999"})};
${MobiletoIpad({marginTop:'5vh',bottom:"-54px"})};
${Ipad({bottom:"-54px"})};
${Ipadto1080({marginTop:'20vh'})} ;
${twok({left:'-1vw',bottom:'-100px'})};
`

