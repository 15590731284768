import {css} from 'styled-components'


////////WIDE RESONSIVE
//MOBILE  UP TO IPHONE 10 SIZE 414 WIDE
export const Mobile =(props)=>{
    return css`
    @media only screen and (max-width:428px){
        ${props}
    }
    `
}

//414P UP TO IPAD SIZE 750P
export const MobiletoIpad =(props)=>{
    return css`
    @media only screen and (min-width:429px){
        ${props}
    }
    `
}


//BOTH IPAD SETTINGS PRO INCLUDD
export const Ipad =(props)=>{
    return css`
    @media only screen and (min-width:750px){
        ${props}
    }
    `
}

//IPAD SIZE TO 1080 P
export const Ipadto1080 =(props)=>{
    return css`
    @media only screen and (min-width:1195px){
        ${props}
    }
    `
}
//IPAD SIZE TO 1080 P
export const desk1080 =(props)=>{
    return css`
    @media only screen and (min-width:1900px){
        ${props}
    }
    `
}
//2K SIZE 
export const twok =(props)=>{
    return css`
    @media only screen and (min-width:2300px){
        ${props}
    }
    `
}

//4K SIZE
export const fourk =(props)=>{
    return css`
    @media only screen and (min-width:3025px){
        ${props}
    }
    `
}
