import React,{useState} from 'react'
import Footer from '../components/Footer'
import MainPage from '../pages/MainPage'
import Specials from './Specials'
import TopOrders from './TopOrders'
import CategorHome from './CategorHome'
import styled from 'styled-components' 
import {
    Grid
} from '@mui/material'
import About from './About'






const HomeContainer = styled.div`

`




const Home = () => {
//     const [displayInstall,setDisplayInstall] = useState(false)
// // helps you detect mobile browsers (to show a relevant message as the process of installing your PWA changes from browser to browser)
// var isMobile = {
//     Android: function () {
//       return navigator.userAgent.match(/Android/i);
//     },
//     BlackBerry: function () {
//       return navigator.userAgent.match(/BlackBerry/i);
//     },
//     iOS: function () {
//       return navigator.userAgent.match(/iPhone|iPad|iPod/i);
//     },
//     Opera: function () {
//       return navigator.userAgent.match(/Opera Mini/i);
//     },
//     Samsung: function () {
//       return navigator.userAgent.match(
//         /SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i,
//       );
//     },
//     Windows: function () {
//       return (
//         navigator.userAgent.match(/IEMobile/i) ||
//         navigator.userAgent.match(/WPDesktop/i)
//       );
//     },
//     any: function () {
//       return (
//         isMobile.Android() ||
//         isMobile.BlackBerry() ||
//         isMobile.iOS() ||
//         isMobile.Opera() ||
//         isMobile.Windows()
//       );
//     },
//   };
  
//   // use this to check if the user is already using your PWA - no need to prompt if in standalone
// const isStandalone=()=>{
//     const isStandalone = navigator.userAgent.match(/iPhone|iPad|iPod/i)
//     if(isStandalone){
//         setDisplayInstall(true)
//     }else{
//         setDisplayInstall(false)
//     }
//     console.log(document.referrer)
//     // if (document.referrer.startsWith("android-app:http://localhost:3000/")) {
//     //   setDisplayInstall(true); // Trusted web app
//     // } else if ("standalone" in navigator || isStandalone) {
//     //   setDisplayInstall(true);
//     // }
//     // setDisplayInstall(false);
//   }




    return (
        <HomeContainer >
            <Grid container direction='column'>
               <Grid item xs={12}>
                     <MainPage  id='home'/> 
                </Grid> 
                <Grid item xs={12}>
                   <Specials /> 

                </Grid>
                {/* <Grid item xs={12}>
                     <TopOrders /> 
                </Grid> */}
                <Grid item xs={12}>
                    <CategorHome id='menu'/>   
                </Grid>
                <Grid item xs={12}>
                     <About id='aboutus'/> 
                </Grid>
                <Grid item xs={12}>
                    <Footer id='footer'/>
                </Grid>
            </Grid>
        </HomeContainer>
    )
}

export default Home
