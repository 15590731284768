import {Suspense,lazy} from 'react'
import { Routes, Route,useLocation} from 'react-router-dom'
import styled from 'styled-components'
import {AnimatePresence} from 'framer-motion'
import PaymentStripe from './pages/PaymentStripe'


//IMPORT PAGES
import Home from './pages/Home'
import { useSelector } from 'react-redux'
const Header =lazy(()=>import('./components/Header'))
const LoginPage = lazy(()=>import('./pages/LoginPage'))
const RegisterPage = lazy(()=>import('./pages/RegisterPage')) 
const Products = lazy(()=>import('./pages/Admin/Products')) 
const Orders = lazy(()=>import('./pages/Admin/Orders')) 
const Users = lazy(()=>import('./pages/Admin/Users')) 
const UpdateProduct = lazy(()=>import('./pages/Admin/UpdateProduct')) 
const OrderPage = lazy(()=>import('./pages/OrderPage')) 
const AdminOrderPage = lazy(()=>import('./pages/Admin/AdminOrderPage')) 
const Userpage = lazy(()=>import('./pages/Userpage')) 
const Successpage = lazy(()=>import('./pages/SuccessPage')) 
const ResetPasswordPage = lazy(()=>import('./pages/ResetPasswordPage')) 




const AppContainer = styled.div`
`


function App() {


const location = useLocation()

  return (
    <AppContainer >

        <Suspense fallback={<div>loading..</div>}><Header/></Suspense>
        <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.key}>
        <Route  path='/login' element={<Suspense fallback={<div>loading....</div>}><LoginPage/></Suspense>}/>
        <Route  path='/register' element={<Suspense fallback={<div>loading....</div>}><RegisterPage/></Suspense>}/>
        <Route  path='/orderpage' element={<Suspense fallback={<div>loading....</div>}><OrderPage/></Suspense>}/>
        <Route  path ='/userpage/:id' element={<Suspense fallback={<div>loading....</div>}><Userpage/></Suspense>}/>
        <Route  exact path ='/paymentstripe' element={<Suspense fallback={<div>loading....</div>}><PaymentStripe/></Suspense>}/>
        <Route  path ='/success/:id' element={<Suspense fallback={<div>loading....</div>}><Successpage/></Suspense>}/>
        <Route  path ='/passwordreset/:id' element={<Suspense fallback={<div>loading....</div>}><ResetPasswordPage/></Suspense>}/>
        <Route exact path='/admin/products' element={<Suspense fallback={<div>loading....</div>}><Products/></Suspense>}/>
        <Route exact path='/admin/product/:id' element={<Suspense fallback={<div>loading....</div>}><UpdateProduct/></Suspense>}/>
        <Route exact path='/admin/users' element={<Suspense fallback={<div>loading....</div>}><Users/></Suspense>}/>
        <Route exact path='/admin/orders' element={<Suspense fallback={<div>loading....</div>}><Orders/></Suspense>}/>
        <Route exact path='/admin/order/:id' element={<Suspense fallback={<div>loading....</div>}><AdminOrderPage/></Suspense>}/>
        <Route  exact path='/' element={<Home/>}/>
        </Routes>
        </AnimatePresence>



    </AppContainer>
  ); 
}

export default App;
