import React,{useState,useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import styled from 'styled-components'
import {Grid} from '@mui/material'
import Products from '../components/Products'
import {listProducts} from '../redux/actions/productActions'



import {
    CategorTagContainer,
  } from '../components/styles/CategoriesTag.styled'


const CategorContainer = styled.div`
background-color:black;
width:100%;
min-height:100vh;
height:100%;
margin-bottom:0;

`

const CategorWrapper = styled.div`
margin-top:100px;
`


const CategorHome = () => {

    //DISPATCH 
     const dispatch = useDispatch()

    //PRODUCTS
    const productList = useSelector((state)=>state.productList)
    const {products} = productList

    //FILTERS HANDLED LOCALLY

    const [newproducts,setNewProducts]=useState()

    const FilterList =(type) =>{
            const allItems = products.filter((f)=>f.type === 'pizza')
            setNewProducts(allItems)
   
    }

    useEffect(()=>{
        dispatch(listProducts())

    },[dispatch])


    
    return (
        <CategorContainer id='menu'>
            
                <Grid container direction='row' justifyContent='center' >
                    <Grid item xs={12}></Grid>
                    <CategorWrapper>
                        <CategorTagContainer>
                        <div style={{textAlign:'center', color:'white', fontWeight:'bolder',fontSize:"30px"}}>CHOOSE YOUR PIZZA</div>
                           
                    </CategorTagContainer>
                    </CategorWrapper>
                    
                    <Grid item xs={12}>
                        <Products style={{marginBottom:'5%'}} products={newproducts === undefined ?products.filter((f)=>f.type === 'pizza'):newproducts}/>
                    </Grid>
                  

                </Grid>
           
            
        </CategorContainer>
    )
}

export default CategorHome




//EXTRA ITEMS THAT WERE REMOVED FOR LATER USE

{/* <CategorButtonWrapper>
                            
<CategorButton value='pizza' onClick={(e)=>FilterList(e.target.value)}></CategorButton> 
<CategorButton value='combo' onClick={(e)=>FilterList(e.target.value)}>COMBO</CategorButton>
<CategorButton value='wings' onClick={(e)=>FilterList(e.target.value)}>WINGS</CategorButton>
<CategorButton value='fries' onClick={(e)=>FilterList(e.target.value)}>FRIES</CategorButton>
<CategorButton value='salad' onClick={(e)=>FilterList(e.target.value)}>SALAD</CategorButton>

</CategorButtonWrapper> */}