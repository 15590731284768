
import styled from 'styled-components'
import React,{useEffect, useState} from 'react'
import StripeCheckout from '../components/StripeCheckout'
import { useSelector,useDispatch } from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {Grid} from '@mui/material'
import secure from '../pics/secure.webp'
import {Mobile} from '../responsive'
import {resetCart} from '../redux/actions/cartActions'
import PaymentOrderPickUp from '../components/PaymentOrderPickUp'

const PaymentOrderContainer = styled.div`
display: flex;
text-align: center;
background-color:transparent;
justify-content:center;
${Mobile({width:'95%'})}

`


const PaymentOrderTitle = styled.h1`
color: Black;
`


const SripeContainer = styled.div`
width:60%;
display:block;
margin-right:auto;
margin-left:auto;
position: relative;
${Mobile({width:'99%'})}
`

const SecurePic = styled.img`
width: 64%;
bottom:0;
${Mobile({width:'100%',marginTop:'10px'})}
`



const PaymentStripe = () => {
    //DISPATCH
    const dispatch = useDispatch()
    //NAVIGATE 
    const navigate = useNavigate()

    //CART INFO FROM REDUX
    const cart = useSelector((state)=>state.cart)
    const {shippingAddress} = cart

    //CART INFO FROM REDUX
    const userLogin = useSelector((state)=>state.userLogin)
    const {userInfo} = userLogin



    // useEffect(()=>{
      
    //     //     dispatch(resetCart())
    //     //    navigate(`/userpage/${userInfo._id}`)
    //     //    setNavFix(true)
    //     // }
    // },[shippingAddress,navigate,userInfo,dispatch,navFix])
  


    if(cart && shippingAddress&& shippingAddress.payOnline===true){
        
        return (
            <PaymentOrderContainer>
    <Grid container>
        <Grid item xs={12}>
        <PaymentOrderTitle>Payment</PaymentOrderTitle> 
        </Grid>
        <Grid item xs={12}>
        <SripeContainer>
                        <StripeCheckout/>
                    </SripeContainer>
        </Grid>
        <Grid item xs={12}>
            <SecurePic src={secure} alt='secured by stripe'/>
        </Grid>
    </Grid>
                   
                    
    
    
            </PaymentOrderContainer>
        )
    }else{
        
        return(
          <PaymentOrderPickUp/>
        )
    }
    
}

export default PaymentStripe