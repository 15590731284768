import styled from 'styled-components'
import {Mobile,Ipad, Ipadto1080, desk1080} from '../../responsive'

export const CategorTagContainer = styled.div`
margin:'5px';

${Mobile({marginTop:'-50px'})}
${Ipad({width:'60vw'})}
${Ipadto1080({width:'40vw'})}
${desk1080({width:'30vw'})}
`

export const CategorButtonWrapper = styled.div`
background-color:#1c1c1b;
border-radius:20px;
display:flex;

`

export const CategorButton = styled.button`
border:none;
padding:10px;
border-radius:20px;
background-color:#1c1c1b;
transition: background-color 2s;
transition: color 1s;
font-weight:bolder;
color:white;

&:hover{
  background-color:red;
  font-size:18px;
}
${Mobile({fontSize:"12px"})}
${Ipad({width:'80vw',fontSize:'20px'})}
`
