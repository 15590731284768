import axios from 'axios'
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
  CART_INCREMENT_ITEM,
  CART_DECREMENT_ITEM,
  CART_CHANGE_SIZE,
  CART_RESET,

} from '../constants/cartConstants'


//ADD TO CART
export const addToCart = (product,values) => async (dispatch, getState) => {
  
   const { data } = await axios.get(`/api/products/${product._id? product._id:product.product?product.product:product[0]._id}`)

  if(data){
    if(data.type === 'combo'){
      dispatch({
        type:CART_ADD_ITEM,
        payload:{
        product: data._id,
        name: data.name,
        image: data.image,
        price: data.price,
        quantity:1,
        sizes:data.sizes,
        type:data.type,
        size:data.sizes[0],
        totalPrice:data.price,
        combos:values
        }
      })
    }else{

  if(data && data.name === 'Custom Pizza'){
    dispatch({
      type: CART_ADD_ITEM,
      payload: {
        product: data._id,
        name: data.name,
        image: data.image,
        price: data.price,
        quantity:1,
        sizes:data.sizes,
        type:data.type,
        size:data.sizes[0],
        totalPrice:data.price,
        customPizza:{dough:values.dough,pizzaSauce:values.pizzaSauce,cheese:values.cheese,toppings:values.topping,extra:values.extra}
      },
    })
  }else{
    dispatch({
      type: CART_ADD_ITEM,
      payload: {
        product: data._id,
        name: data.name,
        image: data.image,
        price: data.price,
        quantity:1,
        sizes:data.sizes,
        type:data.type,
        size:data.sizes[0],
        totalPrice:data.price,
        customPizza:'none'
      },
    })
  }
    }
  }else{
    console.log('No item added to cart')
  }

 
  

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}


//REMOVE FROM CART
export const removeFromCart = (id) => (dispatch, getState) => {
  
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  })

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}


//ADD CART QUANTITY
export const addCartQty = (id) => (dispatch, getState) => {
  console.log(id)
  dispatch({
    type: CART_INCREMENT_ITEM,
    payload: id,
  })

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}


//CHANGE SIZE 
export const changeSize = (id,size) => (dispatch, getState) => {
  dispatch({
    type: CART_CHANGE_SIZE,
    payload: id,
    payloadTwo:size
  })

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}


//DECREASE CART QUANTITY
export const decCartQty = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_DECREMENT_ITEM,
    payload: id,
  })
  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}


//SAVE SHIPPING ADDRESS
export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  })

  localStorage.setItem('shippingAddress', JSON.stringify(data))
}


//SAVEPAYMENTMETHOD
export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  })

  localStorage.setItem('paymentMethod', JSON.stringify(data))
}

//REMOVE FROM CART
export const resetCart = () => (dispatch, getState) => {
  
  dispatch({
    type: CART_RESET
  })

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}
