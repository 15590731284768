import  React,{useState,Suspense} from 'react';
import { useTheme } from '@mui/material/styles';
import ComboMenu from '../components/ComboMenu'
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import {useSelector} from 'react-redux'
import {Combodiv,BoxDiv} from '../components/styles/TopOrders.styled'
import ImageList from '@mui/material/ImageList';
import Box from '@mui/material/Box';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);




function SwipeableTextMobileStepper() {


    //PRODUCTS
    const productList  = useSelector((state)=>state.productList)
    const {products} = productList


    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
  
  
    const handleStepChange = (step) => {
      setActiveStep(step);
    };

    //COMBO ITEMS
    const comboItem = products.filter((p)=>p.type ==='combo')
  




if(comboItem){
    return (
<BoxDiv sx={{ 
      width: '100vw',
      height: '100vh',
      overflow: 'hidden'}}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={6000}
      >
            
              {comboItem.map((c)=>(
            
            <Combodiv key={c._id}  >
               <Suspense fallback={<div>loading...</div>}>
                <ComboMenu product={c} />
                </Suspense>
            </Combodiv>
            
              ))}

</AutoPlaySwipeableViews>
           
          
        </BoxDiv>
      );
}else{
    return <div>loading........</div>
}
  
}

export default SwipeableTextMobileStepper;





