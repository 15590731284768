import React from 'react'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import styled from 'styled-components'
import {Grid} from '@mui/material'
import ProductAccordian from './ProductAccordian'
import cartBackGround from '../pics/cartBackground.webp'
import ComboHandler from './ComboHandler';
import {ComboImage,Combodiv} from '../components/styles/TopOrders.styled'

//STYLE COMPONENTS
export const ListContainer = styled.div`

`
export const BoxContainer = styled.div`
`
export const ExitButton = styled.button`
background-color:transparent;
border:none;
margin:20px;
font-size:40px;
`
export const ListTitle = styled.h1`
text-decoration:underline;
`
export const CartImage = styled.img`
width: 100px;

`
export const AddContainer = styled.div`
 display: flex;
    align-items: right;
    justify-content: right;

`
export const AmountContainer = styled.div`
display: flex;
align-items: center;
font-weight: 700;
`
export const Amount = styled.span`
    display: flex;
    align-items: right;
    justify-content: right;

`
export const Subtitle = styled.h3`

margin-bottom:5px;



border-radius:10px;
width: 25vw;
`
export const TotalBackGround = styled.div`
margin:0px;
text-align:left;



` 
export const ProceedBackGround = styled.button`
position: fixed;
bottom: 0;
margin-bottom:2px;
background-color:white;
border-radius:10px;
width: 50vw;
height:15vh;
border:none;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
` 
export const Proceed = styled.button`
position: fixed;
bottom: 0;
margin-bottom:5px;
padding:10px;
background-color:black;
color:white;
border-radius:10px;
width: 25vw;
`

export const ComboButton = styled.button`
padding:15px;
background-color:green;
font-size:20px;
color:white;
width:150px;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, 320%);


`


const ComboMenu = ({product}) => {




  //RIGHT CART MENU 
  const [state, setState] = React.useState({
  
    right: false,
  });
  
  //TOGGLE DRAWER
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
  
    setState({ ...state, [anchor]: open });
  };
  




    //MAIN HANDLE LIST

  const list = (anchor) => {
 
    return (
      <Box

      role="presentation"
      sx={{width:{xs:'100vw',md:'30vw'}}}
    >


      <Grid container>
        {product?
        <Grid item xs={12}
        style={{ backgroundImage: `url(${cartBackGround})`,
       backgroundColor:'black'}}
        > 
        {product.type ==='combo'?<ComboHandler product={product} toggleDrawer={toggleDrawer} anchor={anchor} />:<ProductAccordian product={product} toggleDrawer={toggleDrawer} anchor={anchor}/>}
              
        </Grid>:'loading......'}


      </Grid>
    </Box>
    );
  };
  

    return (
        <React.Fragment key={'right'}>
          <Combodiv>
          <ComboImage src={product.image}   />
          <ComboButton onClick={toggleDrawer('right', true)}>{product.name}</ComboButton>
          </Combodiv>
         
          <SwipeableDrawer
            anchor={'right'}
            open={state['right']}
            onClose={toggleDrawer('right', false)}
            onOpen={toggleDrawer('right', true)}
            style={{fontFamily:'Finger Paint'}}
          >
            {list('right')}
          </SwipeableDrawer>
        </React.Fragment>
    )
}

export default ComboMenu
