import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_CREATE_RESET,
  PRODUCT_CREATE_FAIL,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_RESET,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_TOP_REQUEST,
  PRODUCT_TOP_SUCCESS,
  PRODUCT_TOP_FAIL,
  SORT_BY_BRAND,
  SORT_BY_NAME,
  SORT_BY_PRICE_LOW_TO_HIGH,
  SORT_BY_PRICE_HIGH_TO_LOW,
  SORT_BY_AVG_CUSTOMER_REVIEW,
  SORT_BY_NEW_ARRIVAL,
} from '../constants/productConstants'

export const productListReducer = (state = { products: [] }, action) => {

//MAIN HANDLERS FOR REDUCERS
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, products: [] }
    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.data.data
      }
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload }

//SUCCESS HANDLERS FOR REDUCERS

      case PRODUCT_CREATE_SUCCESS:
        return {
          ...state,
          products:[...state.products,action.payload]
      }
    case PRODUCT_DELETE_SUCCESS:
      return {
        ...state,
        products:[...state.products.filter((item)=>item._id !== action.payload)]
      }
    case PRODUCT_UPDATE_SUCCESS:
      return {
        ...state,
        products:[...state.products,action.payload]
      }

// SORT AND FILTERS FOR HANDLER
    case SORT_BY_BRAND:
      let newBrandList = action.payload
      return {loading:false,products:newBrandList.sort((a,b)=>a.brand.localeCompare(b.brand))}
    case SORT_BY_NAME:
      let sortByName = action.payload
      return{loading:false,products:sortByName.sort((a,b)=>a.name.localeCompare(b.name))}
    case SORT_BY_PRICE_LOW_TO_HIGH:
      let priceLowtoHigh = action.payload
      return {loading:false,products:priceLowtoHigh.sort((a,b)=>a.price - b.price)}
      case SORT_BY_PRICE_HIGH_TO_LOW:
        let priceHighToLow = action.payload
        return {loading:false,products:priceHighToLow.sort((a,b)=>b.price - a.price)}
    case SORT_BY_AVG_CUSTOMER_REVIEW:
        let avgCustRemove = action.payload
        return {loading:false,products:avgCustRemove.sort((a,b)=>b.rating - a.rating)}
    case SORT_BY_NEW_ARRIVAL:
        let newArrival = action.payload
        return {loading:false,products:newArrival.sort((a,b)=>a.createdAt - b.createdAt)}
    default:
      return state
  }
}

export const productDetailsReducer = (
  state = { product: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { ...state, loading: true }
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: action.payload }
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_REQUEST:
      return { loading: true }

    case PRODUCT_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REQUEST:
      return { loading: true }
    case PRODUCT_CREATE_SUCCESS:
      return { loading: false, success: true, product: action.payload }
    case PRODUCT_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case PRODUCT_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const productUpdateReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return { loading: true }
    case PRODUCT_UPDATE_SUCCESS:
      return { loading: false, success: true, product: action.payload }
    case PRODUCT_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case PRODUCT_UPDATE_RESET:
      return { product: {} }
    default:
      return state
  }
}

export const productReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REVIEW_REQUEST:
      return { loading: true }
    case PRODUCT_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true }
    case PRODUCT_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload }
    case PRODUCT_CREATE_REVIEW_RESET:
      return {}
    default:
      return state
  }
}

export const productTopRatedReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_TOP_REQUEST:
      return { loading: true, products: [] }
    case PRODUCT_TOP_SUCCESS:
      return { loading: false, products: action.payload }
    case PRODUCT_TOP_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
