import React from 'react'
import styled from 'styled-components'
import bgfirst from '../pics/bgfirst.webp'
import a1 from '../pics/a1.webp'
import Carousel from '../components/Carousel'
import {Mobile} from '../responsive'
import {motion} from 'framer-motion'

const MainContainer = styled(motion.div)`
height: 100vh;
overflow:hidden;
background-color:black ;
${Mobile({height:'76vh'})}
`
const BackGroundImage = styled.div`
height:100%;
background:url(${bgfirst}); 
`

const BackGroundImageContainer = styled(motion.div)`
position: relative;
margin:0;
padding:0;
width:100%;
height:100vh;
z-index:999;
`

const PaintDivider = styled.img`
position: absolute;
bottom: -1px;
width: 100%;

${Mobile({bottom:'22vh',zIndex:'99'})}
`
const containerVariants ={
    hidden:{
      
    },
    visible:{
  
      duration:2,
      transition:{delay:1.5,duration:1.5}
  
    },
    exit:{
      x:'-100vw',
      transition:{ease:'easeInOut'}
    }
  }
  
  





const MainPage = () => {
    return (
        <MainContainer id='home' >
            <BackGroundImage>
                <BackGroundImageContainer variants={containerVariants} initial='hidden' whileInView='visible' exit='exit'>
                     <Carousel style={{zIndex:'999'}}/> 
                    <PaintDivider src={a1}/>
                </BackGroundImageContainer>
            </BackGroundImage>
        </MainContainer>
    )
}

export default MainPage
