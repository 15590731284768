import  React,{useState,Suspense,lazy} from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import MainDisplay from '../components/MainDisplay';

const SecondDisplay = lazy(()=>import('./SecondDisplay'))
const ThirdDisplay = lazy(()=>import('./ThirdDisplay'))

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);



function SwipeableTextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);


  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ 
      width: '100vw',
      height: '100vh',
      overflow: 'hidden'}}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={6000}
      >
        <MainDisplay/>
        <Suspense fallback={<div>loading...</div>}>
        <SecondDisplay/>
        </Suspense>
        <Suspense fallback={<div>loading...</div>}>
        <ThirdDisplay/> 
        </Suspense>
       
       
      </AutoPlaySwipeableViews>
      
    </Box>
  );
}

export default SwipeableTextMobileStepper;