import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckOutForm";
import "./App.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe("pk_test_51KDLe3JzF878uh4snZreBkdle7HDJ0EWtmNLaQUt9jdavSrUz6UL6nzFeIBAPwTP9UHG9OzvOV8IvSqGkOwOlYH600fo7ZtHRD");

export default function App() {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);
  const appearance = {
    theme: 'night',
    variables: {
      fontFamily: 'Sohne, system-ui, sans-serif',
      fontWeightNormal: '500',
      borderRadius: '8px',
      colorBackground: 'black',
      colorPrimary: 'green',
      colorPrimaryText: 'white',
      colorText: 'white',
      colorTextSecondary: 'white',
      colorTextPlaceholder: 'white',
      colorIconTab: 'white',
      colorLogo: 'dark'
    },
    rules: {
      '.Input, .Block': {
        border: '1.5px solid var(--colorPrimary)'
      }
    }
  };
  
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}


// import React, { useState, useEffect } from "react";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";

// import CheckoutForm from "./CheckOutForm";
// import {useSelector} from 'react-redux'

// // Make sure to call loadStripe outside of a component’s render to avoid
// // recreating the Stripe object on every render.
// // This is your test publishable API key.
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

// const  StripeCheckout =()=>{
//   const [clientSecret, setClientSecret] = useState("");
//   const cart = useSelector((state)=>state.cart)
//   const {cartItems} = cart

//   const totalPrice = cartItems.reduce((acc,item)=>acc + item.totalPrice,0)

//   useEffect(() => {
//     // Create PaymentIntent as soon as the page loads
//     fetch("/create-payment-intent", {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify({ items: [{ amount:totalPrice }] }),
//     })
//       .then((res) => res.json())
//       .then((data) => setClientSecret(data.clientSecret));
//   }, [totalPrice]);

//   const appearance = {
//     theme: 'night',
//     variables: {
//       fontFamily: 'Sohne, system-ui, sans-serif',
//       fontWeightNormal: '500',
//       borderRadius: '8px',
//       colorBackground: 'black',
//       colorPrimary: 'green',
//       colorPrimaryText: 'white',
//       colorText: 'white',
//       colorTextSecondary: 'white',
//       colorTextPlaceholder: 'white',
//       colorIconTab: 'white',
//       colorLogo: 'dark'
//     },
//     rules: {
//       '.Input, .Block': {
//         border: '1.5px solid var(--colorPrimary)'
//       }
//     }
//   };
  
//   const options = {
//     clientSecret,
//     appearance,
//   };

//   return (
//     <div className="App">
//       {clientSecret && (
//         <Elements options={options} stripe={stripePromise}>
//           <CheckoutForm />
//         </Elements>
//       )}
//     </div>
//   );
// }

// export default StripeCheckout