import {useState} from 'react';
import {useSelector,useDispatch} from 'react-redux'
import styled from 'styled-components'
import {addToCart} from '../redux/actions/cartActions'
import {Formik, Form} from 'formik'
import SelectComponent from './SelectComponent';
import {motion} from 'framer-motion'
import {Mobile, MobiletoIpad, Ipadto1080} from '../responsive'





  const buttonVariants={
    before:{
      opacity: 0,
      x:100,
    },
    after:{
      opacity:1,
      x:0,

      transition:{type:'spring',duration:3, ease:'easeInOut',bounce: 0.25 }
    },  
    tap:{
      scale: 0.9
    },
    hover:{
      scale: 1.1 
    }
  }
  

  const ComboTitleVariants={
    before:{
        opacity:0,
        y:-20
    },
    after:{
      opacity: 1,
      y:0,
      transition:{
        duration:2,
        type:'spring',

      }
    }
  }

  const ComboMainSelectVariants={
    before:{
        opacity:0,
        x:200
    },
    after:{
      opacity: 1,
      x:0,
      transition:{
        duration:2,
        type:'spring',

      }
    }
  }

  const ComboPicVariants={
    before:{
        opacity:0,
    },
    after:{
      opacity: 1,
      transition:{
        duration:2,
        type:'tween',
        delay:.4
      }
    },
    exit:{
      x:'-100vw',
      transition:{ease:'easeInOut',duration:1.5}
    }
  }
  const ComboDesVariants={
    before:{
      opacity:0,
      x:200
  },
  after:{
    opacity: 1,
    x:0,
    transition:{
      duration:2,
      type:'spring',
      delay:.8

    }
  }
  }



//STYLED COMPONENTS
const ComboContainer = styled(motion.div)`
width: 100%;
color:green;
min-height:100vh;
`

const TitleComboContainer = styled(motion.div)`
text-align:center;
`
const ComboTitle  = styled(motion.h1)`

`

const ComponentsDiv = styled(motion.div)`

margin:10px;
border-radius:25px;
display:block;
margin-right:auto;
margin-left:auto;
`

// //STYLED CHECKBOX FOR FORMIK
// const MyCheckBox =({pizzaChoice,disbool,label,...props})=>{
//   const [field,meta] = useField(props)

//   // const errorText = meta.error && meta.touched ?meta.error:''
 
//   return(
//     <FormControlLabel {...field}   label={label}sx={{display:'none',}} style={{marginLeft:'4px',padding:0}} />
//   )
// }


//SELECT
const SelectComboContainer  = styled(motion.div)`
width: 85%;
height:100%;
display:block;
margin-right:auto;
margin-left:auto;
margin-bottom:10px;

`
const SelectComboTopComponent = styled(motion.select)`
width: 100%;
height:50px;
border-radius:10px;
text-align:center;
font-size:25px;
`
const ComboImageContainer = styled(motion.div)`

`
const ComboImage = styled(motion.img)`
width:55%;
display: block;
margin-right:auto;
margin-left:auto;
border-radius:10px;
margin-top:4vh;
`
const ComboChoices = styled(motion.div)`
width:80%;
text-align:center;
border-radius:10px;
padding:20px;
display: block;
margin-right:auto;
margin-left:auto;
`
const ComboButton = styled(motion.button)`

display:block;
margin-right:auto;
margin-left:auto;
padding:15px;
width:55%;
border-radius:10px;
font-size:20px;
font-weight:bolder;
background-color:green;
color:white;
margin-bottom:20px;
margin-top:4vh;
`
const PizzaTitle = styled(motion.h1)`
text-align:left;

`







{/*------------------------------------------------------COMBO HANDLER BEGINING----------------------------------------------------- */}


const ComboHandler = ({product,toggleDrawer,anchor}) => {


//DISPATCH 
 const dispatch = useDispatch()

// ALL ITEMS
const productList = useSelector((state)=>state.productList)
const {products} = productList

//Fetching comboProducts from Products List
const comboProducts = products.filter((f)=>f.type==='combo')

const [comboChoice, setComboChoice] = useState(product._id)
const chosenCombo = comboProducts.filter((f)=>f._id ===comboChoice)


//-----------------------CUSTOM COMBO SUBMIT START--------------------------------//
const onSubmitCombo =(data)=>{
    dispatch(addToCart(chosenCombo,data))
}
//-----------------------CUSTOM COMBO SUBMIT END--------------------------------//


//-----------------------CUSTOM PIZZA START--------------------------------//
//DOUGH FETCH 
const doughProduct = products.filter((f)=>f.type ==='dough')


//PIZZA SAUCE FETCH 
const pizzaSauceProduct =  products.filter((f)=>f.type ==='pizzaSauce')

//TOPPINGS 
const toppingProduct = products.filter((f)=>f.type ==='topping')

//CHEESE 
const cheeseProduct = products.filter((f)=>f.type ==='cheese')



//-----------------------CUSTOM PIZZA END--------------------------------//



//WINGS FETCH 
const wingsProducts = products.filter((f)=>f.type ==='wings')

//DRINK FETCH 
const drinkProducts = products.filter((f)=>f.type ==='drink')

//BREAD FETCH 
const breadProducts = products.filter((f)=>f.type ==='bread')

//SALAD FETCH 
const saladProducts = products.filter((f)=>f.type ==='salad')

//SAUCE FETCH 
const sauceProducts = products.filter((f)=>f.type ==='sauce')

//FRIES FETCH
const friesProducts = products.filter((f)=>f.type ==='fries')




  return (
    <ComboContainer >
        <TitleComboContainer>
            <ComboTitle variants={ComboTitleVariants} initial='before' whileInView='after'> COMBOS </ComboTitle>
        </TitleComboContainer>
        <SelectComboContainer>
        <SelectComboTopComponent variants={ComboMainSelectVariants} initial='before' whileInView={'after'} onChange={(e)=>setComboChoice(e.target.value)} value={comboChoice} >
            {comboProducts.map((cp)=>(
                <option key={cp._id} value={cp._id} >
                    {cp.name}
                </option>
            ))}
        </SelectComboTopComponent>
        </SelectComboContainer>
        <ComboImageContainer>
           <ComboImage src={chosenCombo[0].image} alt={chosenCombo[0].name} variants={ComboPicVariants} initial='before' whileInView={'after'} exit='exit'/>
           <motion.div style={{ textAlign:'center'}} variants={ComboDesVariants} initial='before' whileInView={'after'}>
            <div>{chosenCombo[0].description}</div>
            <div>Price: ${chosenCombo[0].price}</div>
           </motion.div>

           
           <Formik
                 initialValues={{

        
                }}
              
              onSubmit={(data)=>{onSubmitCombo(data)}}
           >
             {({values,errors})=>(
               <Form >
                            <div >
                    {Array.apply(null, { length: chosenCombo[0].pizzasQty }).map((e, i1) => (
                        <ComboChoices key={i1}>

                            <PizzaTitle>Pizza {i1 +1}</PizzaTitle>
                           
                            <SelectComponent
                            name={`dough${i1+1}`}
                            control='select'
                            label='Select Dough'
                            options={doughProduct}
                            />

                        {/*--------------------------------------------sauce start------------------------------------*/}
                        <div >
              
                            <SelectComponent
                            name={`pizzaSauce${i1+1}`}
                            control='select'
                            label='Select Sauce'
                            options={pizzaSauceProduct}
                            border={i1?'yes':'no'}
                            />
                          
                        </div>
                        {/*---------------------------------------------sauce end-----------------------------------------*/}
                        {/*---------------------------------------------cheese start---------------------------------------*/}
                        <div >
                        <SelectComponent
                            name={`cheese${i1+1}`}
                            control='select'
                            label='Select Cheese'
                            options={cheeseProduct}
                            />
                        </div>
                        {/*-------------------------------------------cheese end-------------------------------------------*/}
                        {/*----------------------------------------TOPPING START---------------------------------------------*/}
                                                <div>
                            {Array.apply(null, { length: 3 }).map((e, i) => (
                                <div  key={i}>
                            <SelectComponent
                            name={`toppings${i1+1+':'+i}`}
                            control='select'
                            label='Select toppings'
                            options={toppingProduct}
                            />            
                                </div>
                            ))}

                        </div>
                        {/*TOPPINGS END*/}                           
                        <hr style={{color:'green',backgroundColor:'green',borderColor:'green',marginTop:"4vh"}}/>
                            </ComboChoices>


))}
           </div>
{/*------------------------------------------------------------------------------------------------------------------------------------------ */}
           {/*WINGS SELECTIONS COMBO START */}
         
           <ComboChoices   >
          {/* {chosenCombo[0].pizzasQty>0? <PizzaTitle>Wings</PizzaTitle>:null} */}

           {Array.apply(null, { length: chosenCombo[0].wingsQty }).map((e, i) => (
                        <div  key={i}>
                            <div>
                            <SelectComponent
                            name={`wings ${i+1}`}
                            control='select'
                            label={`Select Wings ${i+1}`}
                            options={wingsProducts}
                            />
                        </div>                          
                        
                            </div>


))}
{/* {chosenCombo[0].wingsQty>0? <hr style={{color:'green',backgroundColor:'green',borderColor:'green',marginTop:"4vh"}}/>:null} */}


           </ComboChoices>           
           {/*WINGS SELECTIONS COMBO END*/}
{/*------------------------------------------------------------------------------------------------------------------------------------------ */}
           {/* DRINK SELECTIONS COMBO START */}
         
           <ComboChoices >
               
                    {Array.apply(null, { length: chosenCombo[0].drinksQty }).map((e, i) => (
                        <div style={{width:'100%'}} key={i}>
                            <div>
                            <SelectComponent
                            name={`drinks${i+1}`}
                            control='select'
                            label={`Choose Drink ${i +1}`}
                            options={drinkProducts}
                            />
                        </div>                          
                        
                            </div>


))}
           </ComboChoices>           
           {/*DRINK SELECTIONS COMBO END*/}
{/*------------------------------------------------------------------------------------------------------------------------------------------ */}
           {/* BREAD SELECTIONS COMBO START */}
         
           <ComboChoices >
                    {Array.apply(null, { length: chosenCombo[0].breadQty }).map((e, i) => (
                        <div style={{width:'100%'}} key={i}>
                            <SelectComponent
                            name={`bread${i+1}`}
                            control='select'
                            label={`Select Bread ${i+1}`}
                            options={breadProducts}
                            />                       
                        
                            </div>


))}
           </ComboChoices>           
           {/*BREAD SELECTIONS COMBO END*/}
{/*------------------------------------------------------------------------------------------------------------------------------------------ */}
           {/* SALAD SAUCE SELECTIONS COMBO START */}
         
           <ComboChoices >
                    {Array.apply(null, { length: chosenCombo[0].saladQty }).map((e, i) => (
                        <div style={{width:'100%'}} key={i}>
                            <SelectComponent
                            name={`salad${i+1}`}
                            control='select'
                            label={`Select Salad ${i+1}`}
                            options={saladProducts}
                            />                        
                        
                            </div>


))}
           </ComboChoices>           
           {/*SALAD SAUCE SELECTIONS COMBO END*/}
{/*------------------------------------------------------------------------------------------------------------------------------------------ */}
           {/* FRIES SELECTIONS COMBO START */}
         
           <ComboChoices >
                    {Array.apply(null, { length: chosenCombo[0].friesQty }).map((e, i) => (
                        <div style={{width:'100%'}} key={i}>
                            <SelectComponent
                            name={`fries${i+1}`}
                            control='select'
                            label={`Select Fries ${i+1}`}
                            options={friesProducts}
                            />                             
                        
                            </div>


))}
           </ComboChoices>           
           {/*FRIES SELECTIONS COMBO END*/}
{/*------------------------------------------------------------------------------------------------------------------------------------------ */}
           {/* DIPPING SAUCE SELECTIONS COMBO START */}
         
           <ComboChoices >
                    {Array.apply(null, { length: chosenCombo[0].sauceQty }).map((e, i) => (
                        <div style={{width:'100%'}} key={i}>
                            <SelectComponent
                            name={`dipping${i+1}`}
                            control='select'
                            label={`Select Dipping Sauce ${i+1}`}
                            options={sauceProducts}
                            />                        
                        
                            </div>


))}
           </ComboChoices>   
             
           {/*DIPPING SAUCE SELECTIONS COMBO END*/} 
           <ComboButton type='submit' variants={buttonVariants} initial='before' whileInView={'after'} whileHover='hover' whileTap={'tap'} >
              Submit Combo
           </ComboButton>
               </Form>
             )}

                                                                   
       </Formik>

        </ComboImageContainer>

    </ComboContainer>
  )
}

export default ComboHandler