import styled from 'styled-components'
import {Mobile,MobiletoIpad,Ipad,Ipadto1080,desk1080,twok,fourk} from '../../responsive'
import {motion} from 'framer-motion'


//ANIME JS FILES INCLUDED DO NOT TOUCH BE VERY CAREFULL WITH ADJUSTMENTS




//STYLED COMPONENTS
export const MainContainer = styled(motion.div)`
height: 100vh;
overflow:hidden;
background-color:transparent ;

`

//TITLE FOR MAIN CAROUSEL
export const Title =styled(motion.h1)`
font-size:60px;
text-align:center;
color:white;
font-family:Finger Paint;
margin:7%;
font-size:100px;
${Mobile({
    marginTop:'100px',
    fontSize:'35px'
    })}

${MobiletoIpad({
marginTop:'100px',
fontSize:'45px'
})}
${Ipad({
marginTop:'100px',
fontSize:'5vw'
})}
${twok({

})}
${fourk({

    marginTop:"190px"
})}
`

// BACKGROUND IMAGE CONTAINER
export const BackGroundImageContainer = styled.div`
position: relative;
margin:0;
padding:0;
width:100%;
height:100vh;
z-index:999;
`

//PIZZA IMAGE 
export const Pizza = styled(motion.img)`
margin: auto;
position: absolute;
top: 210vh; left: 4vw; bottom: 0; right: 0;
width: 75%;
${Mobile({
    top: '40vh', 
    left: '0', 
    bottom: '0', 
    right: '0',
    width:"110%"
    })}

    ${MobiletoIpad({
    top: '80vh', 
    left: '0', 
    bottom: '0', 
    right: '0',
    width:"110%"
    })}

    ${Ipad({
    top: '80vh', 
    left: '0', 
    bottom: '0', 
    right: '0',
    width:"110%"
    })}
    ${Ipadto1080({
    top: '70vh', 
    left: '0', 
    bottom: '0', 
    right: '0',
    width:"80%"
    })}
        ${desk1080({
    top: '80vh', 
    left: '0', 
    bottom: '0', 
    right: '0',
    width:"75%"
    })}
    ${twok({    
    top: '80vh', 
    left: '0', 
    bottom: '0', 
    right: '0',
    width:"75%"})}
    ${fourk({
    top: '80vh', 
    left: '0', 
    bottom: '0', 
    right: '0',
    width:"65%"
    })}
`

//BASIL IMAGE 1 FAR LEFT
export const Basil1 = styled(motion.img)`
    margin:auto;
    position: absolute;
    top: 0; left: 0; bottom:1000px; ; right: 40vw;
    width: 10%;

    ${Mobile({
    top: '0', 
    left: '0', 
    bottom: '-28vh', 
    right: '230px',
    width:"25%"
    })}

        ${MobiletoIpad({
    top: '0', 
    left: '0', 
    bottom: '-68vh', 
    right: '64vw',
    width:"24%"
    })}


    ${Ipad({
    top: '0', 
    left: '0', 
    bottom: '-70vh', 
    right: '64vw',
    width:"17%"
    })}

    
${Ipadto1080({
    top: '0', 
    left: '0', 
    bottom: '-50vh', 
    right: '54vw',
    width:"13%"
  })}

${desk1080({
    top: '0', 
    left: '0', 
    bottom: '-500px', 
    right: '40vw',
    width:"12%"
    })}
${twok({    
    top: '0', 
    left: '0', 
    bottom: '-40vh', 
    right: '40vw',
    width:"10%"})}
`

//BASIL IMAGE 2 MIDDLE
export const Basil2 = styled(motion.img)`
    margin:auto;
    position: absolute;
    top: 0; left: 0; bottom:1000px; right: 200px;
    width: 10%;

    ${Mobile({
    top: '0', 
    left: '0', 
    bottom: '-1vh', 
    right: '0px',
    width:"25%"
    })}
    
        ${MobiletoIpad({
    top: '0', 
    left: '0', 
    bottom: '-35vh', 
    right: '4vw',
    width:"20%"
    })}
            ${Ipad({
    top: '0', 
    left: '0', 
    bottom: '-35vh', 
    right: '4vw',
    width:"25%"
    })}


${Ipadto1080({
    top: '0', 
    left: '0', 
    bottom: '-10vh', 
    right: '4vw',
    width:"12%"
    })}
    ${desk1080({
    top: '0', 
    left: '0', 
    bottom: '-35px', 
    right: '4vw',
    width:"10%"
    })}

    ${twok({
    top: '0', 
    left: '0', 
    bottom: '-1vh', 
    right: '4vw',
    width:"10%"
    })}
`

//BASIL IMAGE 3 FAR RIGHT
export const Basil3 = styled(motion.img)`
    margin:auto;
    position: absolute;
    top: 0; left: 30vw; bottom:1000px; right: 0;
    width: 24%;
    ${Mobile({
    top: '0', 
    left: '220px', 
    bottom: '-30vh', 
    right: '0',
    width:"40vw"
    })}
        ${MobiletoIpad({
    top: '0', 
    left: '380px', 
    bottom: '-70vh', 
    right: '0',
    width:"40%"
    })}
            ${Ipad({
    top: '0', 
    left: '380px', 
    bottom: '-60vh', 
    right: '0',
    width:"45%"
    })}
    ${Ipadto1080({
    top: '0', 
    left: '660px', 
    bottom: '-49vh', 
    right: '0',
    width:"30%"
    })}
        ${desk1080({
    top: '0', 
    left: '660px', 
    bottom: '-330px', 
    right: '0',
    width:"26%"
    })}

${twok({
    top: '0', 
    left: '760px', 
    bottom: '-35vh', 
    right: '0',
    width:"24%"
    })}
${fourk({
    top: '0', 
    left: '960px', 
    bottom: '-40vh', 
    right: '0',
    width:"18%"
})}
    
`