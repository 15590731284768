import {useState} from 'react';
import {useSelector,useDispatch} from 'react-redux'
import {Grid} from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {addToCart} from '../redux/actions/cartActions'
import {Formik, Form, useField} from 'formik'
import {FormControlLabel} from '@material-ui/core'
import * as Yup from 'yup'
import styled from 'styled-components'
import {Mobile,MobiletoIpad, desk1080, Ipadto1080} from '../responsive'
import {motion} from 'framer-motion'
import cartBackGround from '../pics/cartBackground.webp'




const AccordianParentVariants={
  before:{opacity:0},
  after:{
    opacity: 1,
    transition:{
      staggerChildren:0.2
    }
  }
}


const AccordianVariants ={
  before:{
    x:"100vw"
  },
  after:{
    x:'0vw',
    transition:{type:'spring',duration:5, ease:'easeInOut',bounce: 0.25 }
  }
}

const buttonVariants={
  before:{
    opacity: 0,
    y:"100vw"
  },
  after:{
    y:'0vw',
    opacity:1,
    transition:{type:'spring',duration:3, ease:'easeInOut',bounce: 0.25 }
  },  
  tap:{
    scale: 0.9
  },
  hover:{
    scale: 1.1 
  }
}


export const ExitButton = styled.button`
background-color:transparent;
border:none;
font-size:40px;
`


//ACCORDIAN CHECKBOX STYLING
const AccordDivContainer = styled.div`
display:flex;
flex-direction:column;
color:black;

`

//ACCORDIAN CHECKBOX STYLING
const ACCORDIV = styled.div`
display:flex;
margin:0px;
padding:0px;

`
//BUTTON CONTAINER
const ButtonContainer = styled.div`
display: flex;
position:relative;
justify-content: center;
background-color:transparent,
margin-top:min(25%);

${Mobile({marginTop:"min(25%)"})};
`

const ProductProceed = styled(motion.button)`
position: fixed;
bottom: 1vh;
padding:15px;
background-color:green;
color:white;
font-size:20px;
font-weight:bolder;
border-radius:10px;
width: 25vw;



${Mobile({width:"70vw"})};
${MobiletoIpad({width:"98vw"})};
${Ipadto1080({width:"25vw"})}
${desk1080({width:"25vw"})};
`


//STYLED RADIO FOR FORMIK
  const MyRadioBox =({label,...props})=>{
    const [field] = useField(props)
    return(
      <FormControlLabel {...field}  control={<input type='radio' style={{width:'25px',zoom:"2.5",filter: 'hue-rotate(240deg)'}} />} label={label}sx={{display:'none',}} style={{marginLeft:'4px',padding:0}} />
    )
  }
  
  


/////////////////////////////////////////////////////////////////////////////////////////////////////////


const ProductAccordian=({product,toggleDrawer,anchor})=> {

  const validationSchema = Yup.object({

  })

//DISPATCH 
const dispatch = useDispatch()
//ALL TOPPINGS
const allToppings = product.toppings.reduce((acc,item)=>acc+', '+item)

const [expanded, setExpanded] = useState(false);


  //HANDLE CHANGE OF EXPANSION
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };




  

//PRODUCTS LIST

const productList = useSelector((state)=>state.productList)
const {products} = productList

const [chosenCombo,setChosenCombo] = useState('')





//STYLED CHECKBOX FOR FORMIK
const MyCheckBox =({pizzaChoice,disbool,label,...props})=>{
  const [field,meta] = useField(props)


  return(
    <FormControlLabel {...field}  control={<input type='checkbox' disabled={disbool} onChange={setChosenCombo(pizzaChoice)} style={{width:'25px',zoom:"2.5",filter: 'hue-rotate(240deg)'}} />}  label={label}sx={{display:'none',}} style={{marginLeft:'4px',padding:0}} />
  )
}









//SUBMIT THE FORM 
const onSubmitUpdate=(values)=>{
 
if(product){

// HANDLE ARRAYS OF WINGS
  if(values.wings.length>0){
      for(let i =0;i<values.wings.length;i++){
      //CHOSEN WING FILTERED THROUGH ID
       const wings = products.filter((f)=>f._id === values.wings[i])
    dispatch(addToCart(wings[0]))
     }
    }

//HANDLE ARRAYS OF BREAD
  if(values.bread && values.bread.length>0){
  
    for(let i =0;i<values.bread.length;i++){

  //CHOSEN BREAD FILTERED THROUGH ID
  const bread = products.filter((f)=>f._id === values.bread[i])

  dispatch(addToCart(bread[0]))
   }
 }

// HANDLE ARRAYS OF FRIES
  if(values.fries && values.fries.length>0){
    for(let i =0;i<values.fries.length;i++){
     //CHOSEN FRIES FILTERED THROUGH ID
     const fries = products.filter((f)=>f._id === values.fries[i])
  dispatch(addToCart(fries[0]))
   }
 }

//HANDLE ARRAYS OF DIPPING SAUCE
  if(values.dipping && values.dipping.length>0){

  for(let i =0;i<values.dipping.length;i++){

   //CHOSEN DIPPING SAUCE FILTERED THROUGH ID
const dipping = products.filter((f)=>f._id === values.dipping[i])

dispatch(addToCart(dipping[0]))
 }
}

//HANDLE ARRAYS OF DRINKS
  if(values.drink && values.drink.length>0){

    for(let i =0;i<values.drink.length;i++){

     //CHOSEN DRINK FILTERED THROUGH ID
  const drink = products.filter((f)=>f._id === values.drink[i])

  dispatch(addToCart(drink[0]))
   }
 }

//HANDLE ARRAYS OF SALAD
  if(values.salad && values.salad.length>0){

    for(let i =0;i<values.salad.length;i++){

     //CHOSEN SALAD FILTERED THROUGH ID
  const salad = products.filter((f)=>f._id === values.salad[i])

  dispatch(addToCart(salad[0]))
   }
 }

////FINAL SUBMIT /////
    dispatch(addToCart(product,values))    
}else{
  return null
}}

  



//-----------------------CUSTOM PIZZA START--------------------------------//
//DOUGH FETCH 
const doughProduct = products.filter((f)=>f.type ==='dough')

//PIZZA SAUCE FETCH 
const pizzaSauceProduct =  products.filter((f)=>f.type ==='pizzaSauce')

//TOPPINGS 
const toppingProduct = products.filter((f)=>f.type ==='topping')

//CHEESE 
const cheeseProduct = products.filter((f)=>f.type ==='cheese')

//EXTRA ITEM 
const extraItemProduct = products.filter((f)=>f.type ==='extra')

//-----------------------CUSTOM PIZZA END--------------------------------//



//WINGS FETCH 
const wingsProducts = products.filter((f)=>f.type ==='wings')

//DRINK FETCH 
const drinkProducts = products.filter((f)=>f.type ==='drink')

//BREAD FETCH 
const breadProducts = products.filter((f)=>f.type ==='bread')

//SALAD FETCH 
const saladProducts = products.filter((f)=>f.type ==='salad')

//SAUCE FETCH 
const sauceProducts = products.filter((f)=>f.type ==='sauce')




  if(product){
    
    if(product.categories[0]==='customPizza'){
      return(
        <div style={{fontFamily:"Finger Paint",height:"100%",color:'green'}}>
        <Grid container>
          <Grid item xs={12} style={{textAlign:'center',margin:'20px'}}>
              <Grid item xs={12}><h1>{product.name}</h1></Grid>
          </Grid>
          <Grid item xs={12}>
          <Formik 
      initialValues={{
          dough:[],
          pizzaSauce:[],
          cheese:[],
          topping:[],
          extra:[],
          wings:[],
          bread:[],
          salad:[],
          drink:[],
          dipping:[],

        }}
      
      onSubmit={(data)=>{onSubmitUpdate(data)}}
       >
          {({values,errors})=>(
             <Form style={{textAlign:'left',backgroundColor:'transparent'}}>
            <Grid container>
              <Grid item xs={12}>

{/*--------------------------------------------------------------ACCORDIAN ONE---------------------------------------------------------  */}
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{width:'96%', margin:'10px',backgroundImage: `url(${cartBackGround})`}} component={motion.div} variants={AccordianVariants} initial='before' animate={{x:'0vw',transition:{type:'spring',duration:2, ease:'easeInOut',bounce: 0.25 }}} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}  style={{textAlign:'left',padding:'5px'}} >CHOOSE YOUR DOUGH</Typography>
  
          </AccordionSummary>
          <AccordionDetails>

              <AccordDivContainer>
              {doughProduct.map((w,index)=>(
                 <ACCORDIV key={w._id} >
                  <MyRadioBox value={w.name}  name={`dough`} type='radio'/>
                   <h3>{w.name}</h3>
               </ACCORDIV>
              ))}
              </AccordDivContainer>
     
          </AccordionDetails>
        </Accordion>
{/*--------------------------------------------------------------ACCORDIAN ONE---------------------------------------------------------  */}




{/*--------------------------------------------------------------ACCORDIAN TWO---------------------------------------------------------  */}
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{width:'96%', margin:'10px',backgroundImage: `url(${cartBackGround})`}} component={motion.div} variants={AccordianVariants} initial='before' animate={{x:'0vw',transition:{type:'spring',duration:2, ease:'easeInOut',bounce: 0.25,delay:0.2 }}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}  style={{textAlign:'left',padding:'5px'}} >CHOOSE YOUR SAUCE</Typography>
  
          </AccordionSummary>
          <AccordionDetails>

              <AccordDivContainer>
              {pizzaSauceProduct.map((w,index)=>(
                 <ACCORDIV key={w._id} >
                  <MyCheckBox value={w.name}  name={`pizzaSauce`} type='checkbox'/>
                   <h3>{w.name}</h3>
               </ACCORDIV>
              ))}
              </AccordDivContainer>
     
          </AccordionDetails>
        </Accordion>
{/*--------------------------------------------------------------ACCORDIAN TWO---------------------------------------------------------  */}



{/*--------------------------------------------------------------ACCORDIAN THREE START---------------------------------------------------------  */}
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{width:'96%', margin:'10px',backgroundImage: `url(${cartBackGround})`}} component={motion.div} variants={AccordianVariants} initial='before' animate={{x:'0vw',transition:{type:'spring',duration:2, ease:'easeInOut',bounce: 0.25, delay:0.4}}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}  style={{textAlign:'left',padding:'5px'}} >CHOOSE YOUR CHEESE</Typography>
  
          </AccordionSummary>
          <AccordionDetails>

              <AccordDivContainer>
              {cheeseProduct.map((w,index)=>(
                 <ACCORDIV key={w._id} >
                  <MyCheckBox value={w.name}  name={`cheese`} type='checkbox'/>
                   <h3>{w.name}</h3>
               </ACCORDIV>
              ))}
              </AccordDivContainer>
     
          </AccordionDetails>
        </Accordion>
{/*--------------------------------------------------------------ACCORDIAN THREE END---------------------------------------------------------  */}


{/*--------------------------------------------------------------ACCORDIAN FOUR START---------------------------------------------------------  */}
<Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} style={{width:'96%', margin:'10px',backgroundImage: `url(${cartBackGround})`}} component={motion.div} variants={AccordianVariants} initial='before' animate={{x:'0vw',transition:{type:'spring',duration:2, ease:'easeInOut',bounce: 0.25, delay:0.6}}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}  style={{textAlign:'left',padding:'5px'}} >CHOOSE YOUR TOPPINGS</Typography>
  
          </AccordionSummary>
          <AccordionDetails>

              <AccordDivContainer>
              {toppingProduct.map((w,index)=>(
                 <ACCORDIV key={w._id} >
                  <MyCheckBox value={w.name}  name={`topping`} type='checkbox'/>
                   <h3>{w.name}</h3>
               </ACCORDIV>
              ))}
              </AccordDivContainer>
     
          </AccordionDetails>
        </Accordion>
{/*--------------------------------------------------------------ACCORDIAN FOUR END---------------------------------------------------------  */}


{/*--------------------------------------------------------------ACCORDIAN FIVE START---------------------------------------------------------  */}
<Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} style={{width:'96%', margin:'10px',backgroundImage: `url(${cartBackGround})`}} component={motion.div} variants={AccordianVariants} initial='before' animate={{x:'0vw',transition:{type:'spring',duration:2, ease:'easeInOut',bounce: 0.25, delay:0.8}}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}  style={{textAlign:'left',padding:'5px'}}>EXTRA ITEMS</Typography>
  
          </AccordionSummary>
          <AccordionDetails>

              <AccordDivContainer>
              {extraItemProduct.map((w,index)=>(
                 <ACCORDIV key={w._id} >
                  <MyCheckBox value={w.name}  name={`extra`} type='checkbox'/>
                   <h3>{w.name}</h3>
               </ACCORDIV>
              ))}
              </AccordDivContainer>
     
          </AccordionDetails>
        </Accordion>
{/*--------------------------------------------------------------ACCORDIAN FIVE END---------------------------------------------------------  */}


{/*--------------------------------------------------------------ACCORDIAN SIX START---------------------------------------------------------  */}
<Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} style={{width:'96%', margin:'10px',backgroundImage: `url(${cartBackGround})`}} component={motion.div} variants={AccordianVariants} initial='before' animate={{x:'0vw',transition:{type:'spring',duration:2, ease:'easeInOut',bounce: 0.25, delay:1}}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}  style={{textAlign:'left',padding:'5px'}} >TRY OUR AMAZING WINGS</Typography>
  
          </AccordionSummary>
          <AccordionDetails>

              <AccordDivContainer>
              {wingsProducts.map((w,index)=>(
                 <ACCORDIV key={w._id} >
                  <MyCheckBox value={w._id}  name={`wings`} type='checkbox'/>
                   <h3>{w.name}: (${w.price})</h3>
               </ACCORDIV>
              ))}
              </AccordDivContainer>
     
          </AccordionDetails>
        </Accordion>
{/*--------------------------------------------------------------ACCORDIAN SIX END---------------------------------------------------------  */}



        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} style={{width:'96%', margin:'10px',backgroundImage: `url(${cartBackGround})`}} component={motion.div} variants={AccordianVariants} initial='before' animate={{x:'0vw',transition:{type:'spring',duration:2, ease:'easeInOut',bounce: 0.25, delay:1.2}}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}  style={{textAlign:'left',padding:'5px'}}>
             ADD BREADSTICKS 
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
           
            <AccordDivContainer>
            {breadProducts.map((b)=>(
                 <ACCORDIV key={b._id}>
                   <MyCheckBox value={b._id} type='checkbox' name='bread' />
                 <h3>{b.name}: (${b.price})</h3>
               </ACCORDIV>
              ))}
              </AccordDivContainer>
   
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')} style={{width:'96%', margin:'10px',backgroundImage: `url(${cartBackGround})`}} component={motion.div} variants={AccordianVariants} initial='before' animate={{x:'0vw',transition:{type:'spring',duration:2, ease:'easeInOut',bounce: 0.25, delay:1.4}}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}  style={{textAlign:'left',padding:'5px'}}>FRESH SALAD</Typography>
          </AccordionSummary>
          <AccordionDetails>
              <AccordDivContainer>
            {saladProducts.map((s)=>(
                 <ACCORDIV key={s._id}>
                   <MyCheckBox value={s._id} type='checkbox' name='salad' />
                 <h3>{s.name}: (${s.price})</h3>
               </ACCORDIV>
              ))}
              </AccordDivContainer>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')} style={{width:'96%', margin:'10px',backgroundImage: `url(${cartBackGround})`}} component={motion.div} variants={AccordianVariants} initial='before' animate={{x:'0vw',transition:{type:'spring',duration:2, ease:'easeInOut',bounce: 0.25, delay:1.6}}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5bh-content"
            id="panel4bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}  style={{textAlign:'left',padding:'5px'}}>DRINKS</Typography>
          </AccordionSummary>
          <AccordionDetails>

              <AccordDivContainer>
              {drinkProducts.map((d)=>(
                 <ACCORDIV key={d._id}>
                   <MyCheckBox value={d._id}type='checkbox' name='drink' />
                 <h3>{d.name}: (${d.price})</h3>
               </ACCORDIV>
              ))}
              </AccordDivContainer>
      

          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')} style={{width:'96%', margin:'10px',backgroundImage: `url(${cartBackGround})`}} component={motion.div} variants={AccordianVariants} initial='before' animate={{x:'0vw',transition:{type:'spring',duration:2, ease:'easeInOut',bounce: 0.25, delay:1.8}}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6bh-content"
            id="panel4bh-header"
          >
            <Typography sx={{ width: '33%', flexShrink: 0 }}  style={{textAlign:'left',padding:'5px'}}>DIPPING SAUCES</Typography>
          </AccordionSummary>
          <AccordionDetails>
     
              <AccordDivContainer>
              {sauceProducts.map((ds)=>(
                 <ACCORDIV key={ds._id}>
                   <MyCheckBox value={ds._id} type='checkbox' name='dipping' />
                 <h3>{ds.name}: (${ds.price})</h3>
               </ACCORDIV>
              ))}
              </AccordDivContainer>

          </AccordionDetails>
        </Accordion>
        </Grid>

            <Grid item xs={12} >
              <ButtonContainer>
              <ProductProceed type='submit'  onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)} variants={buttonVariants} initial='before' animate='after' whileTap='tap' whileHover='hover'>ADD TO CART</ProductProceed>
              </ButtonContainer>
            </Grid>

            </Grid>
           </Form>
          )}
 
      </Formik> 
          </Grid> 

        </Grid>        
      </div>
      )
    }else{
      return (
        <div style={{height:"100%",color:'green',width:'100%'}}>
          <Grid container>
            <Grid item xs={12}>
                <Grid item xs={12} style={{display:'flex',margin:'10px'}}>
                  <h1 style={{flex:9}}>{product.name}</h1>
                  <ExitButton
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            style={{color:'red',flex:1}}
          >X</ExitButton>
                  </Grid>
                <Grid item xs={12}><p style={{margin:"10px"}}>{allToppings}</p></Grid>
            </Grid>
            <Grid item xs={12}>
            <Formik 
        initialValues={{
            wings:[],
            bread:[],
            salad:[],
            drink:[],
            dipping:[],
  
          }}
        
        onSubmit={(data)=>{onSubmitUpdate(data)}}
         >
            {({values,errors})=>(
               <Form style={{textAlign:'left',backgroundColor:'transparent'}} >
              <Grid container >
                <Grid item xs={12} component={motion.div} variants={AccordianParentVariants} initial='before' animate='after'>
  
      
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{width:'96%', margin:'10px',backgroundImage: `url(${cartBackGround})`}} component={motion.div} variants={AccordianVariants} initial='before' animate={{x:'0vw',transition:{type:'spring',duration:2, ease:'easeInOut',bounce: 0.25 }}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}  style={{textAlign:'left',padding:'5px'}} >WINGS</Typography>
    
            </AccordionSummary>
            <AccordionDetails>
  
                <AccordDivContainer>
                {wingsProducts.map((w,index)=>(
                   <ACCORDIV key={w._id} >
                    <MyCheckBox value={w._id}  name={`wings`} type='checkbox'/>
                     <h3>{w.name}: (${w.price})</h3>
                 </ACCORDIV>
                ))}
                </AccordDivContainer>
       
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{width:'96%', margin:'10px',backgroundImage: `url(${cartBackGround})`}} component={motion.div} variants={AccordianVariants} initial='before' animate={{x:'0vw',transition:{type:'spring',duration:2, ease:'easeInOut',bounce: 0.25,delay:0.2 }}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}  style={{textAlign:'left',padding:'5px'}}>
               ADD BREADSTICKS 
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
             
              <AccordDivContainer>
              {breadProducts.map((b)=>(
                   <ACCORDIV key={b._id}>
                     <MyCheckBox value={b._id} type='checkbox' name='bread' />
                   <h3>{b.name}: (${b.price})</h3>
                 </ACCORDIV>
                ))}
                </AccordDivContainer>
     
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} style={{width:'96%', margin:'10px',backgroundImage: `url(${cartBackGround})`}} component={motion.div} variants={AccordianVariants} initial='before' animate={{x:'0vw',transition:{type:'spring',duration:2, ease:'easeInOut',bounce: 0.25,delay:0.4 }}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}  style={{textAlign:'left',padding:'5px'}}>FRESH SALAD</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <AccordDivContainer>
              {saladProducts.map((s)=>(
                   <ACCORDIV key={s._id}>
                     <MyCheckBox value={s._id} type='checkbox' name='salad' />
                   <h3>{s.name}: (${s.price})</h3>
                 </ACCORDIV>
                ))}
                </AccordDivContainer>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} style={{width:'96%', margin:'10px',backgroundImage: `url(${cartBackGround})`}} component={motion.div} variants={AccordianVariants} initial='before' animate={{x:'0vw',transition:{type:'spring',duration:2, ease:'easeInOut',bounce: 0.25,delay:0.6 }}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5bh-content"
              id="panel4bh-header"
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}  style={{textAlign:'left',padding:'5px'}}>ALL SORTS OF DRINKS</Typography>
            </AccordionSummary>
            <AccordionDetails>
  
                <AccordDivContainer>
                {drinkProducts.map((d)=>(
                   <ACCORDIV key={d._id}>
                     <MyCheckBox value={d._id}type='checkbox' name='drink' />
                   <h3>{d.name}: (${d.price})</h3>
                 </ACCORDIV>
                ))}
                </AccordDivContainer>
        
  
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} style={{width:'96%', margin:'10px',backgroundImage: `url(${cartBackGround})`}} component={motion.div} variants={AccordianVariants} initial='before' animate={{x:'0vw',transition:{type:'spring',duration:2, ease:'easeInOut',bounce: 0.25,delay:0.8 }}}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6bh-content"
              id="panel4bh-header"
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}  style={{textAlign:'left',padding:'5px'}}>DIPPING SAUCES</Typography>
            </AccordionSummary>
            <AccordionDetails>
       
                <AccordDivContainer>
                {sauceProducts.map((ds)=>(
                   <ACCORDIV key={ds._id}>
                     <MyCheckBox value={ds._id} type='checkbox' name='dipping' />
                   <h3>{ds.name}: (${ds.price})</h3>
                 </ACCORDIV>
                ))}
                </AccordDivContainer>
  
            </AccordionDetails>
          </Accordion>
          </Grid>
  
              <Grid item xs={12} >
                <ButtonContainer>
                <ProductProceed type='submit'  onClick={toggleDrawer(anchor, false)} onKeyDown={toggleDrawer(anchor, false)} variants={buttonVariants} initial='before' animate='after' whileTap='tap' whileHover='hover'   >ADD TO CART</ProductProceed >
                </ButtonContainer>
                
              </Grid>
  
              </Grid>
             </Form>
            )}
   
        </Formik> 
            </Grid> 
  
          </Grid>
  
          
        </div>
  
      );
    }
    
  }else{
    return('loading........')
  }
  
}

export default ProductAccordian




